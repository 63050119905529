import React from 'react';
import { Router } from '@reach/router';

import Layout from '../../components/layout';
import OrderDetails from './OrderDetails';

const OrderRouter = () => (
  <Layout className='justify-content-end order-status-page'>
    <Router>
      <OrderDetails path='/order/:orderId' />
    </Router>
  </Layout>
);

export default OrderRouter;
